/* eslint-disable import/exports-last */
import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { TAction, TDispatch } from '@models/types';

import { PembayaranActionType } from './types';

import type { PembayaranAction, PembayaranModel } from './types';
import type { FlightTimeLineDataModel } from '../hasil-pencarian/types';

const DUMMY_Penerbangan: FlightTimeLineDataModel[] =
  [
    {
      departing_flight: [
        {
          airline: {
            iata: 'GA',
            id: 'airlines/2520',
            name: 'Garuda Indonesia'
          },
          aircraft: 'Boeing-737',
          arrival: 'JED',
          arrival_point: 'Saudi Arabia/Jeddah',
          arrival_time: '2023-10-16T01:00:00',
          departure: 'CGK',
          departure_point: 'Indonesia/Jakarta',
          departure_time: '2023-10-15T09:00:00',
          distance: 12831.4697,
          facilities: [
            {
              icon: 'People',
              label: 'Jumlah Bangku 50'
            }
          ],
          flight_id: 'flights/3428878',
          flight_number: 'WY111',
          flight_time_hours: 14.26
        }
      ],
      departure_route: 'CGK-JED',
      inventory_type: 'reserved',
      key: '',
      pax: 0,
      pnr: 'pnr',
      price: 2500000,
      return_route: 'JED-CGK',
      product_id: 'cf7098a515ad4aaf9d4161d829f73fcc',
      returning_flight: [
        {
          airline: {
            iata: 'GA',
            id: 'airlines/2520',
            name: 'Garuda Indonesia'
          },
          aircraft: 'Boeing-737',
          arrival: 'CGK',
          arrival_point: 'Indonesia/Jakarta',
          arrival_time: '2023-11-14T01:00:00',
          departure: 'JED',
          departure_point: 'Saudi Arabia/Jeddah',
          departure_time: '2023-11-13T09:00:00',
          distance: 12831.4697,
          facilities: [
            {
              icon: 'People',
              label: 'Jumlah Bangku 50'
            }
          ],
          flight_id: 'flights/3428828',
          flight_number: 'WY321',
          flight_time_hours: 14.26
        }
      ]
    }
  ];

export interface PropsTAC {
  productId: string
}

export interface PropsPaymentDetails {
  productId: string
  pax: number
}

export interface PropsCreatecart {
  productId: string
  quantity: number
  token: string
}

export interface PropsOrder {
  authorization: string
  cartId: string
  payment_bank: string
  payment_method: string
}

const PembayaranDefault: PembayaranModel = {
  paymentDetails: [],
  resultDetail: [],
  termsAndCondition: {
    booking_fee: [''],
    down_payment: [''],
    full_payment: [''],
    full_payment_approval: [''],
    insert_passenger: [''],
    issued_ticket: [''],
    materialisasi: [''],
    order_reduction: [''],
    others: [''],
    ticket_terms: [''],
    time_limit: ['']
  },
  createCart: {
    cartId: ''
  },
  agentProfile: {
    address: '',
    company_name: '',
    name: '',
    phone: '5'
  },
  orderDetails: {
    departing_flight: [
      {
        airline: {
          iata: '',
          id: '',
          name: ''
        },
        aircraft: '',
        arrival: '',
        arrival_point: '',
        arrival_time: '0',
        departure: '',
        departure_point: '',
        departure_time: '',
        distance: 0,
        facilities: [
          {
            icon: '',
            label: ''
          }
        ],
        flight_id: '',
        flight_number: '',
        flight_time_hours: 0
      }
    ],
    departure_route: 'D',
    inventory_type: '',
    key: '',
    pax: 0,
    pnr: '',
    price: 0,
    return_route: '',
    product_id: '',
    returning_flight: [
      {
        airline: {
          iata: '',
          id: '',
          name: ''
        },
        aircraft: '',
        arrival: '',
        arrival_point: '',
        arrival_time: '',
        departure: '',
        departure_point: '',
        departure_time: '',
        distance: 0,
        facilities: [
          {
            icon: '',
            label: ''
          }
        ],
        flight_id: '',
        flight_number: '',
        flight_time_hours: 0
      }
    ]
  },
  paymentInstruction: {
    ATM: [''],
    Mobile_Banking: ['']
  },
  order: {
    account_no: '',
    timelimit_booking_fee: ''
  },
  booking: {
    bookingFee: 0
  }
};

const PembayaranReducer = (state: PembayaranModel = PembayaranDefault, action: PembayaranAction): PembayaranModel => {
  switch (action.type) {
    case PembayaranActionType.PostPaymentDetailsList:
      return { ...state, paymentDetails: action.data };
    case PembayaranActionType.GetResultDetailList:
      return { ...state, resultDetail: action.data };
    case PembayaranActionType.GetTACbyProductID:
      return { ...state, termsAndCondition: action.data };
    case PembayaranActionType.PostCreateCart:
      return { ...state, createCart: action.data };
    case PembayaranActionType.GetAgentProfile:
      return { ...state, agentProfile: action.data };
    case PembayaranActionType.GetOrderDetails:
      return { ...state, orderDetails: action.data };
    case PembayaranActionType.GetPaymentInstruction:
      return { ...state, paymentInstruction: action.data };
    case PembayaranActionType.PostOrder:
      return { ...state, order: action.data };
    case PembayaranActionType.GetBookingFee:
      return { ...state, booking: action.data };

    default:
      return { ...state };
  }
};

const PembayaranCommand = {
  postPaymentDetailsList: (payload: PropsPaymentDetails, token: string): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/product-id/payment-detail`, payload)
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PembayaranActionType.PostPaymentDetailsList
          });
        });
    };
  },
  getResultDetailsList: (): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_Penerbangan,
          type: PembayaranActionType.GetResultDetailList
        });
      });
    };
  },
  getTACbyProductID: (payload: string, token: string): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).post(
        '/order/1.0/agent/generate-tac-by-product-id',
        {
          productId: payload
        }
      )
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PembayaranActionType.GetTACbyProductID
          });
        });
    };
  },
  postCreateCart: (payload: PropsCreatecart, token: string): Promise<string> => {
    return apiFetch(token).post('/order/1.0/agent/cart', payload)
      .then((response) => {
        if (response.status === 200) {
          return response.data.cartId;
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  },
  postCreateCartFloating: (payload: PropsCreatecart, token: string): Promise<string> => {
    return apiFetch(token).post('/order/1.0/agent/cart', payload)
      .then((response) => {
        if (response.status === 200) {
          return response.data.cartId;
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  },
  getAgentProfile: (params: string, token: string): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).get(`/order/1.0/agent/profile?email=${params}`)
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PembayaranActionType.GetAgentProfile
          });
        });
    };
  },
  getOrderDetails: (type: string, key: string, token: string): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).get(`/inventory/1.0/agent/inventory-${type}?key=${key}`)
        .then((response) => {
          dispatch({
            data: response.data.data[0],
            type: PembayaranActionType.GetOrderDetails
          });
        });
    };
  },
  getPaymentInstruction: (payload: string, token: string): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).get(`/order/1.0/payment-instruction/${payload}`)
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PembayaranActionType.GetPaymentInstruction
          });
        });
    };
  },
  postOrder: (payload: PropsOrder, token: string): TAction<PembayaranAction, string> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/create-order`, payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: PembayaranActionType.PostOrder
            });

            return response.data;
          }

          return 'err';
        }).catch(() => {
          notify.error('Terjadi kesalahan! Silahkan coba beberapa saat lagi');
        });
    };
  },
  postOrderFloating: (payload: PropsOrder, token: string): TAction<PembayaranAction, string> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/create-order-floating`, payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              data: response.data,
              type: PembayaranActionType.PostOrder
            });

            return response.data;
          }

          return 'err';
        }).catch(() => {
          notify.error('Terjadi kesalahan! Silahkan coba beberapa saat lagi');
        });
    };
  },
  getBookingFee: (token: string): TAction<PembayaranAction, void> => {
    return (dispatch: TDispatch<PembayaranAction>) => {
      return apiFetch(token).get(`/order/1.0/generate-booking-fee`)
        .then((response) => {
          dispatch({
            data: response.data,
            type: PembayaranActionType.GetBookingFee
          });
        });
    };
  }
};

export { PembayaranCommand, PembayaranReducer, PembayaranDefault };
